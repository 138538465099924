/*
 * @Description: 
 * @Date: 2024-01-23 10:03:34
 * @LastEditTime: 2024-01-23 10:08:16
 */
import { Skeleton } from '@chakra-ui/react';

interface IProps {
    h: string
    w: string
}

export default (props: IProps) => {
    return (
        <Skeleton h={props.h} w={props.w} />
    )
}
