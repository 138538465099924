/*
 * @Description: 
 * @Date: 2023-12-21 15:35:50
 * @LastEditTime: 2024-08-16 14:20:37
 */

import { Box, HStack, Image, useToast } from "@chakra-ui/react"
import "./index.scss"
import { useEffect } from "react";
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { size } from "lodash";
export default (props: any) => {
    let data = props?.data?.features
    const toast = useToast()
    const PageJump = (url: string) => {
        if (url && url != "#") {
            window.open(url, "_blank");
        } else {
            toast({
                position: "top",
                duration: 1000,
                render: () => (
                    <div className="toast-item warning">
                        <div className="toast warning">
                            {/* <label htmlFor="t-warning" className="close"></label> */}
                            <h3>Warning!</h3>
                            <p>Sorry, Coming soon.</p>
                        </div>
                    </div>
                ),
            })
        }
    }

    useEffect(() => {
        const animatedElement = document.querySelector('#VerifiedGovernance');
        const AeroNyxTokens = document.querySelector('#CheckFeatures');
        const Staking = document.querySelector('#Staking');
        const triggerPosition = 2300;
        window.addEventListener('scroll', () => {
            const scrollPosition = window.scrollY
            if (scrollPosition > triggerPosition) {
                animatedElement?.classList.add('animation2');
                AeroNyxTokens?.classList.add('animation2');
                Staking?.classList.add('animation2');
            }
        });
    }, [])



    return (
        <Box className="Staking">
            <HStack spacing="20px">
                <Box className="Staking_conter" id="Staking" cursor="pointer" onClick={() => PageJump(data[4]?.features_staking?.url)} >
                    <Image className="Staking_img" src={size(data) > 0 && data[4]?.features_staking?.img} alt='' />
                    <Box className="features_staking1">
                        <Box className="Staking_conterName">{size(data) > 0 && data[4]?.features_staking?.name} </Box>
                        <Box className="Staking_conterDesc" fontSize="22px">{size(data) > 0 && data[4]?.features_staking?.desc}</Box>
                    </Box>

                </Box>
                <Box className="Staking_conter" id="VerifiedGovernance" cursor="pointer" onClick={() => PageJump(data[5]?.features_governance?.url)}>
                    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="72" height="72" rx="36" fill="#7462F7" />
                        <path d="M28.9733 46.2667C30.0666 45.0934 31.7333 45.1867 32.6933 46.4667L34.04 48.2667C35.12 49.6934 36.8666 49.6934 37.9466 48.2667L39.2933 46.4667C40.2533 45.1867 41.92 45.0934 43.0133 46.2667C45.3866 48.8 47.32 47.96 47.32 44.4134V29.3867C47.3333 24.0134 46.08 22.6667 41.04 22.6667H30.96C25.92 22.6667 24.6666 24.0134 24.6666 29.3867V44.4C24.6666 47.96 26.6133 48.7867 28.9733 46.2667Z" stroke="#0D0D0D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M30.6666 29.3333H41.3333" stroke="#0D0D0D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M32 34.6667H40" stroke="#0D0D0D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Box className="Staking_features_governance">{size(data) > 0 && data[5]?.features_governance?.name}</Box>
                    <Box className="Verified_img">
                        <Image src={size(data) > 0 && data[5]?.features_governance?.img} alt='' />
                        <Image src={size(data) > 0 && data[5]?.features_governance?.icon_img} alt='' />
                        <Image src={size(data) > 0 && data[5]?.features_governance?.icon_img_lock} alt='' />
                        {/* <Image src={size(data) > 0 && data[5]?.features_governance?.icon_svg} alt='' /> */}
                    </Box>
                </Box>
                <Box
                    onClick={() => PageJump(data[6]?.features_other?.url)}
                    className="Staking_conter Check_Other"
                    bgColor="#7665F9 !important"
                    id="CheckFeatures">
                    <Box>{size(data) > 0 && data[6]?.features_other?.name}</Box>
                    <HStack className="Explore_More" justify="center">
                        <Box>{size(data) > 0 && data[6]?.features_other?.button}</Box>
                        <ArrowForwardIcon></ArrowForwardIcon>
                    </HStack>
                    <Image src={size(data) > 0 && data[6]?.features_other?.img} alt='' />
                </Box>
            </HStack>
        </Box>
    )
}