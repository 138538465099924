/*
 * @Description: 
 * @Date: 2024-01-02 14:44:19
 * @LastEditTime: 2024-08-16 14:20:06
 */
import { Box, Text, Image, HStack, useToast } from '@chakra-ui/react'
import "./Resources.scss"
import "./../index.scss"
import { size } from 'lodash'
export default (props: any) => {
    let data = props.data
    const toast = useToast()
    const PageJump = (url: string) => {
        if (url && url != "#") {
            window.open(url, "_blank");
        } else {
            toast({
                position: "top",
                duration: 1000,
                render: () => (
                    <div className="toast-item warning">
                        <div className="toast warning">
                            {/* <label htmlFor="t-warning" className="close"></label> */}
                            <h3>Warning!</h3>
                            <p>Sorry, Coming soon.</p>
                        </div>
                    </div>
                ),
            })
        }
    }

    return (
        <Box className='MenuButtonList' padding="0">
            <HStack justify="space-between" spacing="0"
                backgroundSize="100% 100%"
                bgImage="https://imagedelivery.net/BV5aFXW_8-vuplL3cruTxw/4d075d04-7388-4ba1-d1e1-b58ee2494b00/public"
            >
                {
                    size(data?.sections) > 0 && data?.sections?.map((item: any, index: number) => {
                        return (
                            <Box
                                onClick={() => PageJump(item.url)}
                                key={index}
                                w="100%"
                                className='Resources'
                            >
                                {
                                    index != 1 && <Box className='Resources_Top'>
                                        <Image
                                            className={index == 0 ? 'Resources_Top_img0' : 'Resources_Top_img2'}
                                            objectFit='cover'
                                            src={item.icon}
                                        />
                                        <Box></Box>
                                    </Box>

                                }
                                <Box h="50%" className='Resources_Text'>
                                    <Text>{item.title} </Text>
                                    <Text fontSize="12px" fontWeight="400" >{item.description}</Text>
                                </Box>
                                {
                                    index == 1 && <Box className=' Resources_Top Resources_bottom'>
                                        <Image
                                            className="Resources_Top_img1"
                                            objectFit='cover'
                                            src={item.icon}
                                        />
                                        <Box></Box>
                                    </Box>

                                }
                            </Box>
                        )
                    })
                }


            </HStack>
        </Box>
    )
}