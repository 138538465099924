/*
 * @Description: 
 * @Date: 2023-12-28 15:20:18
 * @LastEditTime: 2023-12-28 16:03:30
 */
import { Box, Image } from "@chakra-ui/react"
import "./index.scss"
export default (props: any) => {



    return (
        <Box >
            {/* <Image className=" homeBgImg circle" src={props?.bgImage?.circle} alt='Dan Abramov' />
            <Image className="homeBgImg circle2" src={props?.bgImage?.circle2} alt='Dan Abramov' />
            <Image className=" homeBgImg left_blue_light" src={props?.bgImage?.left_blue_light} alt='Dan Abramov' />
            <Image className=" homeBgImg bottom_pink_light" src={props?.bgImage?.bottom_pink_light_main} alt='Dan Abramov' /> */}
        </Box>
    )
}