/*
 * @Description: 
 * @Date: 2023-12-20 16:08:19
 * @LastEditTime: 2024-02-01 17:11:27
 */
import { Box, HStack, Image, useToast } from "@chakra-ui/react"
import "./index.scss"
import Skeleton from "./../Component/Skeleton"
import { useLayoutEffect } from "react"
export default (props: any) => {
    const toast = useToast()
    const PageJump = (url: string) => {
        if (url && url != "#") {
            window.open(url, "_blank");
        } else {
            toast({
                position: "top",
                duration: 1000,
                render: () => (
                    <div className="toast-item warning">
                        <div className="toast warning">
                            {/* <label htmlFor="t-warning" className="close"></label> */}
                            <h3>Warning!</h3>
                            <p>Sorry, Coming soon.</p>
                        </div>
                    </div>
                ),
            })
        }
    }


    useLayoutEffect(() => {
        const animatedElement0 = document.querySelector(`#GovernanceList0`);
        const animatedElement1 = document.querySelector(`#GovernanceList1`);
        const animatedElement2 = document.querySelector(`#GovernanceList2`);
        const animatedElement3 = document.querySelector(`#GovernanceList3`);
        const triggerPosition = 400;
        window.addEventListener('scroll', () => {
            const scrollPosition = window.scrollY

            if (scrollPosition > triggerPosition) {
                animatedElement0?.classList.add('GovernanceAnimation');
                animatedElement1?.classList.add('GovernanceAnimation');
                animatedElement2?.classList.add('GovernanceAnimation');
                animatedElement3?.classList.add('GovernanceAnimation');

            }
        });
    }, [props?.data?.features_icon])





    return (
        <Box className="Governance">
            <HStack>
                {
                    props?.data?.features_icon ?
                        <>
                            {
                                props?.data?.features_icon?.map((item: any, index: number) => {
                                    return (
                                        <Box
                                            onClick={() => PageJump(item.url)}
                                            key={index}
                                            id={`GovernanceList${index}`}
                                            className={`GovernanceList`}>
                                            <Image src={item.icon} alt='Dan Abramov' />
                                            <Box>{item.name}</Box>
                                            <Box>{item.desc}</Box>
                                        </Box>
                                    )

                                })
                            }
                        </>
                        :
                        <>
                            <Box borderRadius="20px" overflow="hidden" w="25%">
                                <Skeleton w="100%" h="300px" />
                            </Box>
                            <Box borderRadius="20px" overflow="hidden" w="25%">
                                <Skeleton w="100%" h="300px" />
                            </Box>
                            <Box borderRadius="20px" overflow="hidden" w="25%">
                                <Skeleton w="100%" h="300px" />
                            </Box>
                            <Box borderRadius="20px" overflow="hidden" w="25%">
                                <Skeleton w="100%" h="300px" />
                            </Box>

                        </>
                }



            </HStack>
        </Box>
    )
}