/*
 * @Description: 
 * @Date: 2024-01-02 15:25:07
 * @LastEditTime: 2024-08-16 14:20:11
 */
import { useState } from "react"
import "./index.scss"
import {
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    Box,
    Image,
    ModalBody,
    HStack,
    Text,
} from '@chakra-ui/react'
export default (props: any) => {
    let data = props.data
    const toast = useToast()
    const PageJump = (url: string) => {
        if (url && url != "#") {
            window.open(url, "_blank");
        } else {
            toast({
                position: "top",
                duration: 1000,
                render: () => (
                    <div className="toast-item warning">
                        <div className="toast warning">
                            {/* <label htmlFor="t-warning" className="close"></label> */}
                            <h3>Warning!</h3>
                            <p>Sorry, Coming soon.</p>
                        </div>
                    </div>
                ),
            })
        }
    }
    return (
        <Modal
            isCentered
            isOpen={props.isOpen}
            onClose={() => props.callback()} >
            <ModalOverlay />
            <ModalContent className="Downloads_ModalContent" >

                <ModalBody className="Downloads_Modal" >
                    <Image src={data?.base?.logo_img_svg} m="auto" p="10px 0" />
                    <Text className="Downloads_ModalTitle"> {data?.download?.title} </Text>
                    <Text className="Downloads_Warning"> {data?.download?.warning} </Text>
                    <Box className="Downloads_Modal_type">
                        <Box>
                            <HStack
                                color={data?.download?.Macos?.version == "Soon" ? "#000 !important" : ''}
                                bgColor={data?.download?.Macos?.version == "Soon" ? "#999 !important" : ''}
                                justify="center"
                                onClick={() => PageJump(data?.download?.Macos?.download)}
                                spacing="10px"

                            >
                                <Image ml="-15px" src={data?.download?.Macos?.img} alt='' boxSize="24px" />
                                <Box>Macos OS</Box>
                            </HStack>
                            <Box>Version:
                                {data?.download?.Macos?.version == "Soon" ?
                                    " in Preparation" :
                                    data?.download?.Macos?.version}
                            </Box>
                        </Box>
                        <Box>
                            <HStack
                                h="47px"
                                color={data?.download?.Windows?.version == "Soon" ? "#000 !important" : ''}
                                bgColor={data?.download?.Windows?.version == "Soon" ? "#999 !important" : ''}
                                justify="center"
                                onClick={() => PageJump(data?.download?.Windows?.download)}>
                                <Image src={data?.download?.Windows?.img} alt='' boxSize="30px" />
                                <Box>Windows OS</Box>
                            </HStack>
                            <Box>Version:
                                {data?.download?.Windows?.version == "Soon" ?
                                    " in Preparation" :
                                    data?.download?.Windows?.version}
                            </Box>
                        </Box>
                        <Box>
                            <HStack
                                h="47px"
                                color={data?.download?.Android[0]?.version == "Soon" ? "#000 !important" : ''}
                                bgColor={data?.download?.Android[0]?.version == "Soon" ? "#999 !important" : ''}
                                justify="center"
                                onClick={() => PageJump(data?.download?.Android[0]?.download)}>
                                <Image ml="-5px" src={data?.download?.Android[0]?.img} alt='' boxSize="40px" />
                                <Box>Android OS</Box>
                            </HStack>
                            <Box>Version:
                                {data?.download?.Android[0]?.version == "Soon" ?
                                    " in Preparation" :
                                    data?.download?.Android[0]?.version}
                            </Box>
                        </Box>
                        <Box>
                            <HStack
                                color={data?.download?.Linux?.version == "Soon" ? "#000 !important" : ''}
                                bgColor={data?.download?.Linux?.version == "Soon" ? "#999 !important" : ''}
                                justify="center"
                                onClick={() => PageJump(data?.download?.Linux?.download)}>
                                <Image ml="-25px" src={data?.download?.Linux?.img} alt='' boxSize="26px" />
                                <Box>Linux OS</Box>
                            </HStack>
                            <Box>Version:
                                {data?.download?.Linux?.version == "Soon" ?
                                    " in Preparation" :
                                    data?.download?.Linux?.version}
                            </Box>
                        </Box>
                        <Box>
                            <HStack
                                color={data?.download?.Iphone[0]?.version == "Soon" ? "#000 !important" : ''}
                                bgColor={data?.download?.Iphone[0]?.version == "Soon" ? "#999 !important" : ''}
                                justify="center"
                                onClick={() => PageJump(data?.download?.Iphone[0]?.download)}>
                                <Image ml="-10px" src={data?.download?.Iphone[0]?.img} alt='' boxSize="26px" />
                                <Box>Iphone OS</Box>
                            </HStack>
                            <Box>Version:
                                {data?.download?.Iphone[0]?.version == "Soon" ?
                                    " in Preparation" :
                                    data?.download?.Iphone[0]?.version}
                            </Box>
                        </Box>
                        <Box>
                            <HStack
                                color={data?.download?.Chrome[0]?.version == "Soon" ? "#000 !important" : ''}
                                bgColor={data?.download?.Chrome[0]?.version == "Soon" ? "#999 !important" : ''}
                                justify="center" onClick={() => PageJump(data?.download?.Chrome[0]?.download)}>
                                <Image src={data?.download?.Chrome[0]?.img} alt='' boxSize="24px" />
                                <Box>Google OS</Box>
                            </HStack>
                            <Box>
                                Version:
                                {data?.download?.Chrome[0]?.version == "Soon" ?
                                    " in Preparation" :
                                    data?.download?.Chrome[0]?.version}
                            </Box>
                        </Box>






                    </Box>

                    <Text className="Downloads_ModalNotice"> {data?.download?.notice} </Text>



                </ModalBody>


            </ModalContent>
        </Modal>
    )
}