/*
 * @Description: 
 * @Date: 2024-01-02 14:44:19
 * @LastEditTime: 2024-08-16 14:19:42
 */
import { Box, Text, Image, HStack, useToast } from '@chakra-ui/react'
import "./Connect.scss"
import "./../index.scss"
import { size } from 'lodash'
export default (props: any) => {
    let data = props.data
    const toast = useToast()
    const PageJump = (url: string) => {
        if (url && url != "#") {
            window.open(url, "_blank");
        } else {
            toast({
                position: "top",
                duration: 1000,
                render: () => (
                    <div className="toast-item warning">
                        <div className="toast warning">
                            {/* <label htmlFor="t-warning" className="close"></label> */}
                            <h3>Warning!</h3>
                            <p>Sorry, Coming soon.</p>
                        </div>
                    </div>
                ),
            })
        }
    }


    const getLogoUrl = (name: any) => {
        let logo = data?.navigation_footer_share?.filter((item: any) => item.name == name)
        if (size(logo) > 0) PageJump(logo[0]?.url)
    }


    return (
        <Box className='MenuButtonList'
            backgroundSize="100% 100%"
            bgImage="https://imagedelivery.net/BV5aFXW_8-vuplL3cruTxw/4d075d04-7388-4ba1-d1e1-b58ee2494b00/public"
        >

            <HStack flexDirection="row">
                <Box className='bluePlot connectPlot'>
                    <Image
                        boxSize='280px'
                        className='connect_img'
                        objectFit='cover'
                        src={data?.connect?.img}
                        alt='Dan Abramov'
                    />
                </Box>

                <Box className='connectText'>
                    <Text>{data?.connect?.name} </Text>
                    <Text>{data?.connect?.desc} </Text>
                    <HStack justify="space-between" mt="120px">
                        <Box color={"#7462f7"} fontSize="18px">
                            {data?.connect?.email}
                        </Box>
                        <HStack spacing="20px">
                            <Image
                                onClick={() => getLogoUrl("Twitter")}
                                boxSize='30px'
                                objectFit='cover'
                                src={data?.connect?.twitter}
                                alt='Dan Abramov'
                            />
                            <Image
                                onClick={() => getLogoUrl("Telegram")}
                                boxSize='30px'
                                objectFit='cover'
                                src={data?.connect?.telegram}
                                alt='Dan Abramov'
                            />

                        </HStack>
                    </HStack>
                </Box>


            </HStack>
        </Box>
    )
}