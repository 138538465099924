/*
 * @Description: 
 * @Date: 2023-12-20 14:10:37
 * @LastEditTime: 2024-04-07 16:33:51
 */


import { HStack, Box, } from '@chakra-ui/react';
import './index.scss';
import Menu_List from "./Menu_List/Menu_list"
import Launch_App from "./Launch_App/Launch_App"
import LogoSvg from "./logo"
import { useState } from 'react';
export default (props: any) => {
    let data = props?.data



    return (
        <Box className='Header'>
            <HStack justify="space-between" color="#fff"   >
                <LogoSvg></LogoSvg>
                <Menu_List data={data} ></Menu_List>
                <Launch_App data={data} LanguageCallback={(e) => props.LanguageBack(e)}></Launch_App>
            </HStack>
        </Box>

    );
}
