/*
 * @Description: 
 * @Date: 2024-01-23 10:19:54
 * @LastEditTime: 2024-07-31 16:31:48
 */
import { HStack, Box } from '@chakra-ui/react';
import Skeleton from "./../../Component/Skeleton"
import { ChevronDownIcon } from '@chakra-ui/icons'
import "./Menu_list.scss"
import Features from "./../Features/Features"
import Build from "./../Build/Build"
import Resources from "./../Resources/Resources"
import Connect from "./../Connect/Connect"
interface IProps {
    data: any
}

export default (props: IProps) => {
    let data = props?.data


    return (
        <HStack justify="space-between" className="Menu_list">
            {/* <Box textAlign="center">
                <Box>
                    {
                        data?.features_navigation?.name
                            ?
                            "Home"
                            :
                            <Skeleton h='24px' w="100%" />
                    }

                </Box>
            </Box> */}
            <Box className='FeaturesHeight'>
                <HStack justify="center">
                    {
                        data?.features_navigation?.name
                            ?
                            <>
                                <Box>{data?.features_navigation?.name}</Box>
                                <ChevronDownIcon />
                            </>
                            :
                            <Skeleton h='24px' w="100%" />
                    }
                    <Features data={data?.features_navigation} />
                </HStack>

            </Box>
            <Box className='buildHeight'>
                <HStack justify="center">

                    {
                        data?.build?.name
                            ?
                            <>
                                <Box>{data?.build?.name}</Box>
                                <ChevronDownIcon />
                            </>
                            :
                            <Skeleton h='24px' w="100%" />
                    }
                    <Build data={data?.build} />
                </HStack>

            </Box>
            <Box className='ResourcesHeight'>
                <HStack lineHeight="74px" justify="center">
                    {
                        data?.resources?.name
                            ?
                            <>
                                <Box>{data?.resources?.name}</Box>
                                <ChevronDownIcon />
                            </>
                            :
                            <Skeleton h='24px' w="100%" />
                    }
                    <Resources data={data?.resources} />
                </HStack>

            </Box>
            <Box className='ConnectHeight' >
                <HStack justify="center">

                    {
                        data?.resources?.name
                            ?
                            < >
                                <Box>Connect</Box>
                                <ChevronDownIcon />
                            </>
                            :
                            <Skeleton h='24px' w="100%" />
                    }
                    <Connect data={data} />
                </HStack>

            </Box>




        </HStack>
    )
}