/*
 * @Description: 
 * @Date: 2023-12-20 14:39:34
 * @LastEditTime: 2024-08-16 14:20:16
 */

import { Box, HStack, Button, useToast, Image } from "@chakra-ui/react"
import "./index.scss"
import { CheckIcon } from '@chakra-ui/icons'
import HomeBgImg from "./homeBgImg"
import Downloads from "./Downloads"
import { size } from "lodash";
import { useState, useEffect } from "react";
import Skeleton from "./../Component/Skeleton"
import Bgjsx from "./Bgjsx"


export default (props: any) => {
    const [activeStep, handleStepChange] = useState<any>(0);
    const [isOpen, setIsOpen] = useState(false)
    const toast = useToast()
    const PageJump = (url: string) => {
        if (url && url != "#") {
            window.open(url, "_blank");
        } else {
            toast({
                position: "top",
                duration: 1000,
                render: () => (
                    <div className="toast-item warning">
                        <div className="toast warning">
                            {/* <label htmlFor="t-warning" className="close"></label> */}
                            <h3>Warning!</h3>
                            <p>Sorry, Coming soon.</p>
                        </div>
                    </div>
                ),
            })
        }
    }




    useEffect(() => {
        let TitleLength: any = size(props?.data?.home_screen) > 0 ? size(props?.data?.home_screen[4]?.value) : 0;
        const interval = setInterval(() => {
            handleStepChange((prevIndex: any) => {
                return prevIndex == TitleLength - 1 ? 0 : prevIndex + 1;
            });
        }, 8000);
        return () => {
            clearInterval(interval);
        };
    }, [props?.data]);



    useEffect(() => {
        setTimeout(() => {
            home_page_font_carousel()
        }, 1000)
    }, [props?.data]);


    const home_page_font_carousel = () => {
        const items: any = document.getElementsByClassName('carousel-item');
        if (size(items) > 0) {
            items[0].style.opacity = "1";
            let currentIndex = 0;
            const scrollCarousel = () => {
                items[currentIndex].style.opacity = "0";
                currentIndex++
                if (currentIndex >= size(items)) {
                    currentIndex = 0
                }
                items[currentIndex].style.opacity = "1";

            }

            setInterval(scrollCarousel, 6000);
        }

    }






    return (
        <Box className="Home"
            bgImage={"https://storage.googleapis.com/suifiles/layer.svg"}
        >
            <Box w="100vw" h="100vh" position="absolute" overflow="hidden"  > <Bgjsx></Bgjsx></Box>
            <Box className="HomeConter" >
                {
                    props?.data?.base && <HomeBgImg bgImage={props?.data?.base}></HomeBgImg>
                }

                <Downloads isOpen={isOpen} callback={() => setIsOpen(false)} data={props?.data}   ></Downloads>

                {
                    size(props?.data?.home_screen) > 0
                        ?
                        <HStack className="GuaranteeExplore" mt="80px" onClick={() => PageJump(props?.data?.home_screen[3]?.url)}>
                            <Button className="GuaranteeExploreButton">

                                {size(props?.data?.home_screen) > 0
                                    && props?.data?.home_screen[3]?.name}
                            </Button>

                            <Box cursor="pointer">
                                {size(props?.data?.home_screen) > 0
                                    && props?.data?.home_screen[3]?.desc}
                            </Box>
                        </HStack>
                        :
                        <Box borderRadius="30px" overflow="hidden" w="50%">
                            <Skeleton w="100%" h="50px" />
                        </Box>
                }


                <Box className="DePIN_privacy_network_banner">
                    <Box
                        style={{
                            // opacity: "1",
                            position: "static"
                        }}
                    >
                        <Box className="AeroNyx_LXD  AeroNyxText carousel-container">
                            <Box className="carousel">
                                {
                                    props?.data?.home_page_font_carousel?.map((item: any, index: any) => {
                                        return <Box className="carousel-item">

                                            <Box

                                                onClick={() => PageJump(item.url)}
                                                dangerouslySetInnerHTML={{ __html: item.title }}></Box>


                                            <Box

                                                lineHeight="30px"
                                                className="AeroNyx_illustrate AeroNyxText"
                                                dangerouslySetInnerHTML={{ __html: item.subtitle }}>
                                            </Box>

                                        </Box>
                                    })
                                }


                            </Box>
                        </Box>

                    </Box>
                </Box>





                <HStack marginTop="24px" spacing="24px">

                    {
                        size(props?.data?.home_screen) > 0 ?
                            <>
                                <Box
                                    bgColor="#5d4af6"
                                    className="HomeButton"
                                    _hover={{ boxShadow: 'none' }}
                                    onClick={() => setIsOpen(true)}>
                                    {size(props?.data?.home_screen) > 0 && props?.data?.home_screen[1]?.name}
                                </Box>
                            </>
                            : <Box borderRadius="30px" overflow="hidden" w="150px">
                                <Skeleton w="150px" h="40px" />
                            </Box>
                    }
                    {
                        size(props?.data?.home_screen) > 0 ?
                            <>
                                <Box className="HomeButton" onClick={() => PageJump(props?.data?.home_screen[2]?.url)} >{size(props?.data?.home_screen) > 0
                                    && props?.data?.home_screen[2]?.name}</Box>
                            </>
                            : <Box borderRadius="30px" overflow="hidden" w="150px">
                                <Skeleton w="150px" h="40px" />
                            </Box>
                    }

                </HStack>
                {/* <HStack fontWeight="500" marginTop="60px" color="#fff">
                    <CheckIcon />
                    <Box>The AeroNyx DePIN Client: Guaranteed Privacy. No Access, No Storage, No Detection of Your Device Information</Box>
                </HStack> */}
                <Box className="Supported_by">
                    <Box marginTop="60px" color="#fff" fontSize="18px" fontWeight="500"> {props?.data?.partners?.name}</Box>


                    <Box className="slider" marginTop="24px">
                        <Box className="Supported_by_Image slide-track"   >
                            {
                                size(props?.data?.partners?.partner) > 0 && props?.data?.partners?.partner.map((item: any, index: number) => {
                                    return (
                                        <Image src={item.icon} alt="" w="160px" />
                                    )
                                })
                            }
                            {
                                size(props?.data?.partners?.partner) > 0 && props?.data?.partners?.partner.map((item: any, index: number) => {
                                    return (
                                        <Image src={item.icon} alt="" w="160px" className="min750" />
                                    )
                                })
                            }
                            {
                                size(props?.data?.partners?.partner) > 0 && props?.data?.partners?.partner.map((item: any, index: number) => {
                                    return (
                                        <Image src={item.icon} alt="" w="160px" className="min750" />
                                    )
                                })
                            }
                            {
                                size(props?.data?.partners?.partner) > 0 && props?.data?.partners?.partner.map((item: any, index: number) => {
                                    return (
                                        <Image src={item.icon} alt="" w="160px" className="min750" />
                                    )
                                })
                            }
                            {
                                size(props?.data?.partners?.partner) > 0 && props?.data?.partners?.partner.map((item: any, index: number) => {
                                    return (
                                        <Image src={item.icon} alt="" w="160px" className="min750" />
                                    )
                                })
                            }
                        </Box>
                    </Box>

                </Box>
            </Box>
        </Box>
    )
}