/*
 * @Description: 
 * @Date: 2024-01-23 11:00:38
 * @LastEditTime: 2024-01-23 11:08:18
 */

export default () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.6525 20.9098C7.6225 20.9098 7.5825 20.9298 7.5525 20.9298C5.6125 19.9698 4.0325 18.3798 3.0625 16.4398C3.0625 16.4098 3.0825 16.3698 3.0825 16.3398C4.3025 16.6998 5.5625 16.9698 6.8125 17.1798C7.0325 18.4398 7.2925 19.6898 7.6525 20.9098Z" fill="white" />
            <path d="M20.9391 16.4498C19.9491 18.4398 18.2991 20.0498 16.2891 21.0198C16.6691 19.7498 16.9891 18.4698 17.1991 17.1798C18.4591 16.9698 19.6991 16.6998 20.9191 16.3398C20.9091 16.3798 20.9391 16.4198 20.9391 16.4498Z" fill="white" />
            <path d="M21.0191 7.71047C19.7591 7.33047 18.4891 7.02047 17.1991 6.80047C16.9891 5.51047 16.6791 4.23047 16.2891 2.98047C18.3591 3.97047 20.0291 5.64047 21.0191 7.71047Z" fill="white" />
            <path d="M7.64656 3.08859C7.28656 4.30859 7.02656 5.54859 6.81656 6.80859C5.52656 7.00859 4.24656 7.32859 2.97656 7.70859C3.94656 5.69859 5.55656 4.04859 7.54656 3.05859C7.57656 3.05859 7.61656 3.08859 7.64656 3.08859Z" fill="white" />
            <path d="M15.4878 6.59C13.1678 6.33 10.8278 6.33 8.50781 6.59C8.75781 5.22 9.07781 3.85 9.52781 2.53C9.54781 2.45 9.53781 2.39 9.54781 2.31C10.3378 2.12 11.1478 2 11.9978 2C12.8378 2 13.6578 2.12 14.4378 2.31C14.4478 2.39 14.4478 2.45 14.4678 2.53C14.9178 3.86 15.2378 5.22 15.4878 6.59Z" fill="white" />
            <path d="M6.59 15.4917C5.21 15.2417 3.85 14.9217 2.53 14.4717C2.45 14.4517 2.39 14.4617 2.31 14.4517C2.12 13.6617 2 12.8517 2 12.0017C2 11.1617 2.12 10.3417 2.31 9.56172C2.39 9.55172 2.45 9.55172 2.53 9.53172C3.86 9.09172 5.21 8.76172 6.59 8.51172C6.34 10.8317 6.34 13.1717 6.59 15.4917Z" fill="white" />
            <path d="M21.9963 12.0017C21.9963 12.8517 21.8763 13.6617 21.6863 14.4517C21.6063 14.4617 21.5462 14.4517 21.4662 14.4717C20.1362 14.9117 18.7763 15.2417 17.4062 15.4917C17.6662 13.1717 17.6662 10.8317 17.4062 8.51172C18.7763 8.76172 20.1462 9.08172 21.4662 9.53172C21.5462 9.55172 21.6063 9.56172 21.6863 9.56172C21.8763 10.3517 21.9963 11.1617 21.9963 12.0017Z" fill="white" />
            <path d="M15.4878 17.4102C15.2378 18.7902 14.9178 20.1502 14.4678 21.4702C14.4478 21.5502 14.4478 21.6102 14.4378 21.6902C13.6578 21.8802 12.8378 22.0002 11.9978 22.0002C11.1478 22.0002 10.3378 21.8802 9.54781 21.6902C9.53781 21.6102 9.54781 21.5502 9.52781 21.4702C9.08781 20.1402 8.75781 18.7902 8.50781 17.4102C9.66781 17.5402 10.8278 17.6302 11.9978 17.6302C13.1678 17.6302 14.3378 17.5402 15.4878 17.4102Z" fill="white" />
            <path d="M15.7633 15.7633C13.2622 16.0789 10.7378 16.0789 8.23667 15.7633C7.92111 13.2622 7.92111 10.7378 8.23667 8.23667C10.7378 7.92111 13.2622 7.92111 15.7633 8.23667C16.0789 10.7378 16.0789 13.2622 15.7633 15.7633Z" fill="white" />
        </svg>

    )
}