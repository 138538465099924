/*
 * @Description: 
 * @Date: 2024-08-19 14:16:48
 * @LastEditTime: 2024-08-19 19:53:56
 */
import { Box, HStack } from "@chakra-ui/react"
import { size, replace } from "lodash";
import React, { useState, useEffect } from 'react';

export default () => {
    const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        // Set target date (August 31st, 12:00 PM, New York Time)
        const targetDate: any = new Date();
        targetDate.setMonth(7); // August is month 7 (0-indexed)
        targetDate.setDate(31);
        targetDate.setHours(12, 0, 0, 0); // 12:00:00 PM

        const updateTime = () => {
            // Get current time in New York timezone
            const now = new Date();
            const options = { timeZone: 'America/New_York', hour12: false };
            const currentDateTime = new Intl.DateTimeFormat('zh-CN', options).format(now);
            const [hours, minutes, seconds] = currentDateTime.split(':').map(Number);
            const currentNewYorkTime: any = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));

            // Calculate the difference in milliseconds
            const timeDifference = targetDate - currentNewYorkTime;

            if (timeDifference > 0) {
                const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));
                const minutesLeft = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                const secondsLeft = Math.floor((timeDifference % (1000 * 60)) / 1000);
                setTimeLeft({ hours: hoursLeft, minutes: minutesLeft, seconds: secondsLeft });
            } else {
                // Time reached, reset to zero
                setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
                clearInterval(intervalId); // Stop the interval
            }
        };

        updateTime();
        const intervalId = setInterval(updateTime, 1000);

        return () => clearInterval(intervalId);
    }, []);







    return (
        <HStack fontSize="16px">
            <Box textAlign="center" w="60px" borderRadius="10px" overflow="hidden" >
                <Box bg="#7462f7" p="2px 0">{timeLeft.hours < 10 ? 0 : ''}{timeLeft.hours}</Box>
                <Box bg="#7362f7da" p="2px 0">Hrs</Box>
            </Box>
            <Box>:</Box>
            <Box textAlign="center" w="60px" borderRadius="10px" overflow="hidden" >
                <Box bg="#7462f7" p="2px 0">{timeLeft.minutes < 10 ? 0 : ''}{timeLeft.minutes}</Box>
                <Box bg="#7362f7da" p="2px 0">Mins</Box>
            </Box>
            <Box>:</Box>
            <Box textAlign="center" w="60px" borderRadius="10px" overflow="hidden" >
                <Box bg="#7462f7" p="2px 0">{timeLeft.seconds < 10 ? 0 : ''}{timeLeft.seconds}</Box>
                <Box bg="#7362f7da" p="2px 0">Sec</Box>
            </Box>
        </HStack>
    )
}