/*
 * @Description: 
 * @Date: 2024-01-02 10:38:57
 * @LastEditTime: 2024-08-16 14:19:48
 */
import { Box, Text, Image, useToast, Collapse, HStack } from '@chakra-ui/react'
import "./Features.scss"
import "./../index.scss"
import { size } from 'lodash'
export default (props: any) => {
    let data = props.data
    const toast = useToast()
    const PageJump = (url: string) => {
        if (url && url != "#") {
            window.open(url, "_blank");
        } else {
            toast({
                position: "top",
                duration: 1000,
                render: () => (
                    <div className="toast-item warning">
                        <div className="toast warning">
                            {/* <label htmlFor="t-warning" className="close"></label> */}
                            <h3>Warning!</h3>
                            <p>Sorry, Coming soon.</p>
                        </div>
                    </div>
                ),
            })
        }
    }
    return (
        <Box className='MenuButtonList' >
            <HStack justify="space-between"
                backgroundSize="100% 100%"
                bgImage="https://imagedelivery.net/BV5aFXW_8-vuplL3cruTxw/4d075d04-7388-4ba1-d1e1-b58ee2494b00/public"
            >
                <HStack
                    flexWrap="wrap"
                    justify="space-between"
                    spacing="20px"

                    className='FeaturesPage_TextW' >
                    {
                        size(data?.sections) > 0 && data?.sections?.map((item: any, index: number) => {
                            return (
                                <Box w="45%" onClick={() => PageJump(item.url)} key={index} mb="20px">
                                    <Image
                                        boxSize='30px'
                                        objectFit='cover'
                                        src={item.icon}
                                    />
                                    <Text m="10px 0">{item.title} </Text>
                                    <Text fontSize="12px" fontWeight="400" >{item.description}</Text>
                                </Box>
                            )
                        })
                    }
                </HStack>

                <Box className='bluePlot'>
                    <Image
                        boxSize='300px'
                        objectFit='cover'
                        src={data?.img}
                        alt='Dan Abramov'
                    />
                </Box>
            </HStack>

        </Box>
    )
}