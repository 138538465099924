/*
 * @Description: 
 * @Date: 2024-08-15 14:38:03
 * @LastEditTime: 2024-08-26 18:12:12
 */
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    useToast,
    ModalBody,
    HStack,
    Box,
    Input
} from '@chakra-ui/react'
import { useEffect, useLayoutEffect, useState } from "react"
import NewYorkTime from "./NewYorkTime"
import "./index.scss"
const AeroNyx_Intro = require("./../img/AeroNyx_Intro.mp4")
const videoImg = require("./../img/bd13aad3dd3b8b193de20dcf8b8268e.jpg")
const next = require("./../img/next.png")

export default () => {
    const toast = useToast()
    const [isOpenModal, setIsOpenModal] = useState(true)
    const [videoPlayBool, setvideoPlayBool] = useState(true)


    useEffect(() => {
        if (document.getElementById("video")) {
            videoplay()
        }
    }, [])

    const videoplay = () => {
        const video: any = document.getElementById("video")
        video.play()
        setvideoPlayBool(false)
    }

    return (
        <Modal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} isCentered size="3xl" >
            <ModalOverlay />
            <ModalContent
                p="20px"
                bg="rgba(0, 0, 0, 1)"
                color="#000"
                boxShadow="0px 0px 30px rgba(255, 255, 255, 0.5)"
            >
                <Box className='ModalCloseButton' color="#fff" > <ModalCloseButton /></Box>
                <ModalBody >

                    <Box position="relative" overflow="hidden" borderRadius="10px">
                        <video
                            playsInline
                            poster={videoImg}
                            id='video'
                            autoPlay
                            loop
                            muted
                            controls={false}
                            preload="auto"
                            style={{
                                // position: 'absolute',
                                // top: 0,
                                // left: 0,
                                width: '100%',
                                height: '400px',
                                objectFit: 'cover',
                            }}
                        >
                            <source src={AeroNyx_Intro} type="video/mp4"></source>

                        </video>
                        {
                            // videoPlayBool && <Box className='VideoImg' onClick={(e) => videoplay()} >
                            //     <img src={next} alt="" />
                            // </Box>
                        }

                        <HStack className='AISociety' justify="space-between">
                            <Box>AI Society Infrastructure</Box>
                            <Box color="#7462f7" textAlign="right">Coming Soon!</Box>
                        </HStack>
                        <HStack className='Limited' justify="space-between">
                            <Box>Limited to <span>5,000</span> AeroNyx NFTs (ANPB)</Box>
                            <Box className='NewYorkTime'>
                                <NewYorkTime></NewYorkTime>
                            </Box>
                        </HStack>
                    </Box>
                    <HStack justify="space-between" m="25px 0">
                        <Box fontSize="36px" color="#7462F7" fontWeight="600" className='OnlyForSale'>Only For Sale 2 SOL</Box>
                        <Box
                            cursor="pointer"
                            bg="#5D4AF6"
                            fontSize="18px"
                            className='ViewFullDetails'
                            color="#fff" textAlign="center" p="5px 20px" borderRadius="20px" onClick={() =>
                                toast({
                                    position: "top",
                                    duration: 3000,
                                    render: () => (
                                        <div className="toast-item warning">
                                            <div className="toast warning">

                                                <h3>Warning!</h3>
                                                <p>Sorry, Coming soon.</p>
                                            </div>
                                        </div>
                                    ),
                                })}   >View Full Details</Box>
                    </HStack>
                    <Box borderBottom="1px solid #292929"  ></Box>
                    <Box fontSize="26px" color="#fff" fontWeight="600" m="20px 0" className='GetAirdrop'>Get Airdrop</Box>
                    <HStack flexWrap="wrap" color="#888" fontSize="18px" className='GetAirdropText'>
                        <Box>· DePHY & AeroNyx Co-Branded Hardware</Box>
                        <Box>· 1000 AeroNyx Tokens</Box>
                        <Box>· NFT Invitation Code</Box>
                        <Box>· 1 Year free privacy network activation code</Box>
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}