/*
 * @Descripttion:
 * @Date: 2023-06-30 11:18:58
 */
import "./index.scss";
import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
// import Dialog from "@mui/material/Dialog";
export default (props: any) => {
  let data = props?.data
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <div className="can_i_help_you" onClick={() => setIsModalOpen(true)} >
        <img
          className="can_i_help_you_img"
          src={data?.base?.logo_img_svg}
          alt=""
        />
        <div>AeroNyx Chatgpt</div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} >
        <ModalOverlay />
        <ModalContent>

          <ModalBody p="0 !important" >
            <iframe
              src="https://www.chatbase.co/chatbot-iframe/AopDH-zBCpq1rUmLIenKj"
              width="100%"
              height="700px"
              // frameborder="0"
              scrolling="auto"
            ></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>

    </>
  );
};
