/*
 * @Description: 
 * @Date: 2023-12-21 16:54:28
 * @LastEditTime: 2024-08-16 14:21:09
 */


import { Box, HStack, Heading, Input, Button, Text, useToast } from "@chakra-ui/react"
import "./index.scss"
import { useState } from "react";
import axios from "axios";
export default (props: any) => {
    let data = props.data
    const toast = useToast()
    const [email, setEmail] = useState("");
    const [title, setTitle] = useState<null | string>(null);
    const Join = () => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
        if (!regex) {
            return toast({
                position: "top",
                duration: 3000,
                render: () => (
                    <Box className="toast-item error" >
                        <div className="toast error">
                            <h3>Error!</h3>
                            <p>Please enter the correct email address</p>
                        </div>
                    </Box>
                ),
            })
        }
        axios
            .post("https://AeroNyx.network/api/v1/cms/email", {
                email,
            })
            .then((response) => {
                // The request is successful, and the response data is processed
                if (response.data.status == 200) {
                    toast({
                        position: "top",
                        duration: 3000,
                        render: () => (
                            <div className="toast-item success">
                                <div className="toast success">
                                    <h3>Success!</h3>
                                    <p>success.</p>
                                </div>
                            </div>
                        ),
                    })
                    setEmail("");
                } else {
                    toast({
                        position: "top",
                        duration: 3000,
                        render: () => (
                            <Box className="toast-item error" >
                                <div className="toast error">
                                    <h3>Error!</h3>
                                    <p>{response.data.message}</p>
                                </div>
                            </Box>
                        ),
                    })
                }
            })
            .catch((error) => {
                // An error occurred in the request, processing error
                console.error(error);
            });
    };


    return (
        <Box
            className="Ready_supercharge"
            padding="80px 0"
            bgSize={"100% 100%"}
            bgImage={data?.Community_mailbox?.img}   >
            <Box>
                <Heading >{data?.Community_mailbox?.title}</Heading>
                <Text >{data?.Community_mailbox?.desc}</Text>
                <HStack>
                    <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Email address..."
                        _placeholder={{ color: "rgba(255, 255, 255, 0.6)" }}></Input>
                    <Button className="Join_Community" _hover={{ boxShadow: 'none' }} onClick={() => Join()}>Join Community</Button>
                </HStack>
            </Box>


        </Box>
    )
}