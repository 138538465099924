/*
 * @Description: 
 * @Date: 2023-12-21 15:18:56
 * @LastEditTime: 2024-08-16 14:20:26
 */

import { Box, HStack, Text, Image, useToast } from "@chakra-ui/react"
import "./index.scss"
import { size } from "lodash";
import { useEffect } from "react"
export default (props: any) => {
    let data = props?.data?.features
    useEffect(() => {
        const animatedElement = document.querySelector('#NetworkingCenter');
        const triggerPosition = 1650;
        window.addEventListener('scroll', () => {
            const scrollPosition = window.scrollY
            if (scrollPosition > triggerPosition) {
                animatedElement?.classList.add('NetworkingAnimation');
            }
        });
    }, [])


    const toast = useToast()
    const PageJump = (url: string) => {
        if (url && url != "#") {
            window.open(url, "_blank");
        } else {
            toast({
                position: "top",
                duration: 1000,
                render: () => (
                    <div className="toast-item warning">
                        <div className="toast warning">
                            {/* <label htmlFor="t-warning" className="close"></label> */}
                            <h3>Warning!</h3>
                            <p>Sorry, Coming soon.</p>
                        </div>
                    </div>
                ),
            })
        }
    }


    return (
        <Box className="Networking_System">
            <Box >
                <HStack justify="space-between" id="NetworkingCenter" cursor="pointer" onClick={() => PageJump(data[3]?.features_network?.url)}  >
                    <Box className="Networking_System_content" >
                        <Text className="spanColor">Networking System</Text>
                        <Text>
                            {size(data) > 0 && data[3]?.features_network?.name}
                        </Text>
                        <Text>
                            {size(data) > 0 && data[3]?.features_network?.desc}
                        </Text>
                        <HStack className="Learn_more">
                            <Box>Learn more</Box>
                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.89944 9.92893C5.99621 13.8322 5.99621 20.1678 9.89944 24.0711C13.8027 27.9743 20.1383 27.9743 24.0416 24.0711C27.9448 20.1678 27.9448 13.8322 24.0416 9.92893C20.1383 6.0257 13.8027 6.0257 9.89944 9.92893ZM20.1949 14.5251L20.1949 18.7678C20.1949 18.9799 20.1101 19.1637 19.9757 19.2981C19.8414 19.4324 19.6575 19.5173 19.4454 19.5173C19.0353 19.5173 18.6958 19.1779 18.6958 18.7678L18.6958 16.3353L15.026 20.0052C14.736 20.2951 14.2552 20.2951 13.9653 20.0052C13.6754 19.7153 13.6754 19.2345 13.9653 18.9445L17.6352 15.2747L15.2027 15.2747C14.7926 15.2747 14.4532 14.9352 14.4532 14.5251C14.4532 14.115 14.7926 13.7756 15.2027 13.7756L19.4454 13.7756C19.8555 13.7756 20.1949 14.115 20.1949 14.5251Z" fill="#5E4BF5" />
                            </svg>
                        </HStack>
                    </Box>
                    <Box className="Networking_System_img" >
                        <Image src={size(data) > 0 && data[3]?.features_network?.features_network_img_1} alt='' />
                        <Image src={size(data) > 0 && data[3]?.features_network?.features_network_img_2} alt='' />
                        <Image src={size(data) > 0 && data[3]?.features_network?.features_network_img_3} alt='' />
                        <Image src={size(data) > 0 && data[3]?.features_network?.features_network_img_4} alt='' />
                        <Image src={size(data) > 0 && data[3]?.features_network?.features_network_img_5} alt='' />
                    </Box>
                </HStack>


            </Box>
        </Box>
    )
}