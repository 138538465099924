/*
 * @Description: 
 * @Date: 2023-12-21 17:09:08
 * @LastEditTime: 2024-08-24 13:54:49
 */


import { Box, HStack, Heading, Divider, Button, Text, useToast } from "@chakra-ui/react"
import "./index.scss"
import { size } from "lodash"
export default (props: any) => {
    let data = props.data
    const toast = useToast()
    const PageJump = (url: string) => {
        if (url && url != "#") {
            window.open(url, "_blank");
        } else {
            toast({
                position: "top",
                duration: 1000,
                render: () => (
                    <div className="toast-item warning">
                        <div className="toast warning">
                            {/* <label htmlFor="t-warning" className="close"></label> */}
                            <h3>Warning!</h3>
                            <p>Sorry, Coming soon.</p>
                        </div>
                    </div>
                ),
            })

        }
    }






    return (
        <Box className="Are_interested_AeroNyx"
            backgroundSize="100% 100%"
            bgImage="https://imagedelivery.net/BV5aFXW_8-vuplL3cruTxw/071b0cac-c3f1-408d-2f16-711379007400/public">
            <Box bgColor="rgba(0,0,0,0.8)">
                <Box   >
                    <HStack className="Are_interested_AeroNyxSpace">
                        <HStack spacing="100px">
                            {
                                size(data?.navigation_footer) > 0 && data?.navigation_footer.map((item: any, index: number) => {
                                    return (
                                        <Box className="Company ">
                                            <Heading size="lg" >{item.title}</Heading>
                                            {
                                                item.list.map((listItem: any, listIndex: number) => {
                                                    return (
                                                        <Text key={listIndex} onClick={() => PageJump(listItem?.url)}  >{listItem?.name}</Text>
                                                    )
                                                })
                                            }
                                        </Box>
                                    )
                                })
                            }
                        </HStack>

                        <Box className="Are_interested_AeroNyx_Button">
                            <Heading>{size(data?.navigation_footer_right_button) > 0 && data?.navigation_footer_right_button[0]?.name}</Heading>
                            <Button className="Contact_Head" onClick={() => PageJump(data?.navigation_footer_right_button[0]?.url || "")}>
                                {size(data?.navigation_footer_right_button) > 0 && data?.navigation_footer_right_button[0]?.button_name}</Button>
                        </Box>

                    </HStack>



                    <Heading className="AeroNyxHeading"  >Aero<label>Nyx</label></Heading>

                    <Divider mt="50px" />

                    <HStack justify="space-between" mt="20px" pb="50px" className="AeroNyx_Copyrights">
                        <HStack spacing="20px">
                            {
                                size(data?.navigation_footer_share) > 0 &&
                                data?.navigation_footer_share.map((item: any, index: number) => {
                                    return (
                                        <Text key={index}
                                            onClick={() => PageJump(item?.url)} cursor="pointer">{item?.name}</Text>
                                    )
                                })
                            }
                        </HStack>
                        <Text>2024 Amaterasu LLC Copyrights</Text>

                    </HStack>


                </Box>
            </Box>
        </Box>
    )
}