/*
 * @Description: 
 * @Date: 2023-12-21 14:45:09
 * @LastEditTime: 2024-08-16 14:19:28
 */

import { Box, HStack, Button, Image, useToast } from "@chakra-ui/react"
import "./index.scss"
import { size } from "lodash";
import Skeleton from "./../Component/Skeleton"
import { useEffect } from "react";
export default (props: any) => {
    let data = props?.data?.features
    const toast = useToast()
    const PageJump = (url: string) => {
        if (url && url != "#") {
            window.open(url, "_blank");
        } else {
            toast({
                position: "top",
                duration: 1000,
                render: () => (
                    <div className="toast-item warning">
                        <div className="toast warning">
                            {/* <label htmlFor="t-warning" className="close"></label> */}
                            <h3>Warning!</h3>
                            <p>Sorry, Coming soon.</p>
                        </div>
                    </div>
                ),
            })
        }
    }
    useEffect(() => {
        const animatedElement = document.querySelector('#LSD_Service_LaaS');
        const AeroNyxTokens = document.querySelector('#AeroNyxTokens');
        const triggerPosition = 1200;
        window.addEventListener('scroll', () => {
            const scrollPosition = window.scrollY
            if (scrollPosition > triggerPosition) {
                animatedElement?.classList.add('animation');
                AeroNyxTokens?.classList.add('animation');
            }
        });
    }, [])




    return (
        <Box className="Features">
            <Box>
                <Button className="FeaturesButton" _hover={{ boxShadow: 'none' }}>Features</Button>
                <HStack justify="space-between">
                    {

                        size(data) > 0 ?
                            <Box className="AeroNyx_loaded">
                                {size(data) > 0 && data[0]?.name}

                            </Box>
                            :
                            <Box borderRadius="30px" overflow="hidden" w="70%">
                                <Skeleton w="100%" h="80px" />
                            </Box>
                    }

                    <svg className="FeaturesSvg" width="119" height="119" viewBox="0 0 119 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M41.3106 28.0377C58.6543 18.0243 80.8316 23.9667 90.8449 41.3104C100.858 58.6541 94.916 80.8314 77.5722 90.8448C60.2285 100.858 38.0512 94.9158 28.0379 77.5721C18.0245 60.2284 23.9668 38.0511 41.3106 28.0377Z" stroke="white" stroke-width="2.11171" stroke-linejoin="round" />
                        <path d="M72.3215 50.9582L44.0583 67.2759" stroke="white" stroke-width="2.11171" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M66.3491 73.2486L44.0587 67.2759L50.0314 44.9854" stroke="white" stroke-width="2.11171" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </HStack>

                <HStack spacing="20px" className="Features_LSD_Service_LaaS_List">
                    <Box className="LSD_Service_LaaS" id="LSD_Service_LaaS"

                        onClick={() => PageJump(data[1]?.features_laas?.url)}
                    >

                        <Box className="LSD_Service_LaaSText  LSD_Service_LaaSText1"

                        >
                            <Box> {size(data) > 0 && data[1]?.features_laas?.name}</Box>
                            <Box>
                                {size(data) > 0 && data[1]?.features_laas?.desc}
                            </Box>

                        </Box>
                        <Box className="LSD_Service_LaaSbgcolor1">
                            <Image src={size(data) > 0 && data[1]?.features_laas?.img} alt='' />
                        </Box>
                    </Box>
                    <Box className="LSD_Service_LaaS" id="AeroNyxTokens"
                        onClick={() => PageJump(data[2]?.features_token?.url)}

                    >
                        <Box className="LSD_Service_LaaSText">
                            <Box> {size(data) > 0 && data[2]?.features_token?.name}</Box>
                            <Box>{size(data) > 0 && data[2]?.features_token?.desc}</Box>
                        </Box>

                        <Box className="  LSD_Service_LaaSbgcolor2">
                            <Image src={size(data) > 0 && data[2]?.features_token?.img} alt='' />
                        </Box>
                    </Box>

                </HStack>
            </Box>
        </Box>
    )
}